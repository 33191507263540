import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"

import { Medailon, H2 } from "@igloonet-web/shared-ui"

import Produkty from "../../../images/reference/hannah/produkty.jpg"
import Prodejna from "../../../images/reference/hannah/prodejna.jpg"
import Logo from "../../../images/reference/hannah/logo.png"
import Jaroslav from "../../../images/reference/hannah/jaroslav.png"
import Vyzkum from "../../../images/reference/hannah/vyzkum.jpg"

import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"

class Hannah extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Zákaznický výzkum pro Hannah | igloonet</title>
          <meta
            name="description"
            content="Pomocí hloubkových rozhovorů jsme pomohli české outdoorové značce Hannah
            pochopit, jak jejich klienti přemýšlí nad nákupy a podle čeho se rozhodují."
          />
        </Helmet>
        <ReferenceHeader
          companyName="HANNAH"
          heading="Zákaznický výzkum pro Hannah"
          date="srpen - listopad 2021"
        />

        <ReferenceImageText
          right
          img={Prodejna}
          eager
          alt=""
          logo={Logo}
          logoAlt="logo Hannah"
          link="//hannah.cz/"
        >
          <p>
            Pomocí hloubkových rozhovorů jsme pomohli české outdoorové značce
            Hannah pochopit, jak jejich klienti přemýšlí nad nákupy a podle čeho
            se rozhodují.
          </p>
          <p>
            Hannah je česká značka outdoorového oblečení, která mimo jiné
            vlastní známý obchod Rockpoint a méně známou lezeckou značku Rafiki.
            Momentálně se nachází v těžké situaci – z jedné strany tlačí na cenu
            levné sportovní e-shopy, z druhé přichází požadavky na kvalitu a
            design (konkurence typu Patagonia, The North Face nebo
            Arc&apos;teryx). Hannah proto hledá novou prodejní strategii.
          </p>
        </ReferenceImageText>

        <ReferenceImageText>
          <h2>Zvolte správnou výzkumnou metodu</h2>
          <p>
            Úprava pozice na trhu není jen tak a zodpovědná rozhodnutí musí stát
            na datových základech. V Hannahu proto chtěli provést dotazníkové
            šetření a zjistit, jak je zákazníci vnímají. Zajímalo je, podle čeho
            se při nákupu rozhodují (design, kvalita, cena…), jaká mají od
            produktů očekávání a jak jim značku co nejlépe prodat. S tímhle
            zadáním nás oslovili.
          </p>
          <p>
            Hned v úvodu jsme nesouhlasili s výběrem výzkumné metody. Dotazník
            je na komplexní otázky poměrně omezený a Hannah by z dat nedostala
            vhled, který potřebuje. Domluvili jsme se proto na (z našeho pohledu
            vhodnější) cestě kvalitativního výzkumu. Jeho výstupy se dají lépe
            uchopit i představit, a usnadňují tak klíčová strategická
            rozhodnutí.
          </p>
        </ReferenceImageText>

        <ReferenceImageText img={Produkty} alt="">
          <h2>Jak jsme vedli rozhovory</h2>
          <p>
            Pro sběr dat jsme zvolili částečně strukturované hloubkové
            rozhovory. Jde o zlatý střed mezi čistým proudem otázek (interview)
            a volným (nestrukturovaným) rozhovorem. Jejich síla tkví hlavně v
            tom, že výzkumník nezapomene na klíčové otázky, ale nelpí na
            konkrétním pořadí. Celkově se ptá tak, aby byl průběh co
            nejpřirozenější.
          </p>
          <p>
            Při dotazování jsme dělali vše proto, aby se respondenti cítili
            dobře. Byli tak sdílnější a dali nám více ze svého pohledu na věc.
            Prioritou samozřejmě byly otázky a hypotézy klienta, ale zajímala
            nás právě i témata, která zákazníci zmiňovali sami od sebe a jsou
            pro ně důležitá. Pomohlo nám to vytvořit ucelenější obrázek o celém
            nákupním cyklu.
          </p>
        </ReferenceImageText>

        <ReferenceImageText right img={Vyzkum} alt="">
          <h2>
            Kvalitativní výzkum zajistí podrobné odpovědi na stanovené otázky a
            zároveň přinese nové poznatky pro další analýzu.
          </h2>
          <p>
            Pro Hannah jsme vedli celkem 8 rozhovorů v délce 90-120 minut.
            Ideálně jich doporučujeme 10-15, ale v našem případě byla klíčová
            rychlost a omezený rozpočet. Po 8 jsme zjistili, že máme velmi
            konzistentní data a další výzkum by patrně nepřinesl nic nového
            (došlo by k tzv. saturaci). Pokud bychom neviděli žádné jasné
            závěry, samozřejmě jsme měli domluvené rozšíření a prodloužení
            celého výzkumu.
          </p>
        </ReferenceImageText>

        <ReferenceImageText>
          <h2>Rozdíly v nákupních cyklech</h2>
          <p>
            Během každého rozhovoru jsme procházeli všechny fáze nákupního cyklu
            podle známého modelu STDC. Silně jsme ho však přizpůsobili
            klientovým přáním a hypotézám. Zjišťovali jsme například:
          </p>
          <ul>
            <li>počáteční motivaci k nákupu,</li>
            <li>vnímání obsluhy v kamenných prodejnách,</li>
            <li>samotný nákupní proces,</li>
            <li>používání produktů v reálu,</li>
            <li>důležitost reklamačního procesu.</li>
          </ul>
          <p>
            Hned v prvních rozhovorech se potvrdila síla kvalitativního výzkumu
            a správnost našeho rozhodnutí. Ukázalo se, že{" "}
            <strong>zákazníci mají 2 diametrálně odlišné nákupní cykly</strong>{" "}
            (začali jsme jim říkat rychlý a pomalý){" "}
            <strong>a v každém hrají roli jiná kritéria</strong>. Pokud jim na
            nějaké věci opravdu záleží (pomalý cyklus), vybírají dlouho a
            rozhodují se podle kvality a doporučení známých. Když však produkt
            nemá prioritu (rychlý cyklus), mnohem důležitější jsou cena, design
            a práce obsluhy.{" "}
            <strong>Při dotazníkovém šetření bychom tohle nezjistili</strong>.
            Cykly by se v otázce na kritéria pomíchaly a výsledkem by byl datový
            mišmaš, který by Hannahu nijak nepomohl.
          </p>
          <p>
            Rozhovory jsme po dokončení výzkumu analyzovali tzv. tematickou
            analýzou. Laicky řečeno jsme v nich hledali společná témata. Kromě
            nákupních cyklů jsme zjistili další zajímavé informace, které budou
            při určování strategie Hannahu důležité. Identifikovali jsme
            například:
          </p>
          <ul>
            <li>relevantní reklamní a informační kanály,</li>
            <li>cenové hladiny pro různé produktové kategorie,</li>
            <li>motivaci k nákupu konkrétní značky.</li>
          </ul>
        </ReferenceImageText>

        <ReferenceImageText halfImgSize>
          <H2>Značka nebo prodejna?</H2>
          <p>
            Síla kvalitativního výzkumu se projevila i v tom, že jsme kromě
            klientových otázek a hypotéz odhalili další faktory, které budou pro
            Hannah klíčové. Zákazníci jsou například mnohem věrnější prodejnám
            než konkrétním značkám. Často mají své oblíbené obchody, kam
            pravidelně a prioritně chodí, zatímco značky jsou spíše druhotné
            kritérium, které pomáhá s rozhodováním. Zkoumali jsme i to, jak se
            taková „věrnost“ prodejně buduje.
          </p>
          <p>
            Tyto informace mohou klientovi pomoci, pokud bude například řešit
            propojení svých značek Hannah a Rockpoint nebo obecně při rozvoji
            kamenných a online obchodů.
          </p>
          <p>
            Samozřejmě, i kvalitativní výzkum má svoje slabiny. Bylo by extrémně
            riskantní, kdyby velká firma měnila strategii na základě 8
            rozhovorů, proto vždy doporučujeme nové závěry otestovat
            kvantitativním výzkumem (třeba právě dotazníkem). V Hannahu měli
            několik starších studií, které byly s našimi zjištěními konzistentní
            (až na drobné detaily), díky tomu mohli rovnou začít se
            strategickými kroky.
          </p>
          <p>
            Potřebujete pochopit nákupní chování svých zákazníků i vy? Vytvoříme
            vám výzkum na míru. Pojďme to probrat.
          </p>
        </ReferenceImageText>

        <Medailon
          personName="Jaroslav Červinka"
          position="obchodní ředitel"
          img={Jaroslav}
        >
          <p>
            Pro přípravu nové kolekce a celkové plánování na příští rok jsme
            chtěli zjistit, jak nás zákazníci vnímají. Výsledek nám potvrdil
            část našich odhadů, ale také nám pomohl vyvrátit některé domněnky.
            Vzhledem k rozsahu dat a času jsme do nové kolekce zapracovali ty,
            které se prolínaly všemi dotazovanými...
          </p>
        </Medailon>
        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default Hannah
